import User from './User'; // импортируем продукт
import React, { useState } from 'react';

function App() {

	function id(length = 16) {
		let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';		
		let res = '';
		
		for (let i = 0; i < length; i++) {
			res += chars[Math.floor(Math.random() * chars.length)];
		}
		return res;
	}
	const initUsers = [
		{id: id(), name: 'user1', surname: 'surn1', age: 30},
		{id: id(), name: 'user2', surname: 'surn2', age: 31},
		{id: id(), name: 'user3', surname: 'surn3', age: 32},
	];

	const [users, setUsers] = useState(initUsers);

	const result = users.map(user => {
		return <User
			key ={user.id}
			name={user.name}
			surname={user.surname}
			age={user.age}
		/>;
	});

		return <div>
			{result}
		</div>;
	}
	
export default App;