import React from 'react';

function User({ name, surname, age }) {
	return <table>
		<tbody>
		<tr>
		<td>name: {name}</td>
		<td>surname: {surname}</td>
		<td>age: {age}</td>
	</tr>
	</tbody>
	</table>;
}

export default User;